import CryptoJS from 'crypto-js';

import { phoneFormaterRegex } from './regex';

export const removeAccents = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    // return removeDiacritics(obj);
    return str.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
  }
  return str;
};

export const normalizeString = (str) => {
  return (str && typeof str === 'string' ? str : (str && str.toString()) || '')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

const secuRegex =
  /^([123478])(?:[\s.-]{0,5})(\d{2})(?:[\s.-]{0,5})(0[1-9]|1[0-2]|[2-3][\d]|4[0-2]|[5-9]\d)(?:[\s.-]{0,5})(\d{2}|2[AB])(?:[\s.-]{0,5})(\d\d[1-9]|\d[1-9]0|[1-9]\d0)(?:[\s.-]{0,5})(\d\d[1-9]|\d[1-9]0|[1-9]\d0)(?:[\s.-]{0,5})(0[1-9]|[1-8]\d|9[0-7])$/g;

export const secuNumbFormater = (secu) => {
  if (secu && secu.length === 15) secu = secu.replace(secuRegex, '$1 $2 $3 $4 $5 $6 $7');
  return secu;
};

export const phoneFormater = (phone) => {
  if (phoneFormaterRegex.test(phone || '')) phone = phone.replace(phoneFormaterRegex, '0$2 $3 $4 $5 $6');
  return phone;
};

const postalCodeRegex = /^(\s*\d{1,2})([\s.-]*)(\d{3})$/g;
export const postalCodeFormater = (postalCode) => {
  postalCode = postalCode.replaceAll('\\s+', '');
  if (postalCode.toString().length === 4) {
    postalCode = '0' + postalCode;
  }
  if (postalCode && postalCode.match(postalCodeRegex)) {
    postalCode = postalCode.replaceAll('\\s+', '').replace(postalCodeRegex, '$1 $3');
  }
  return postalCode;
};

export const filterAndOrderList = (objList, orderList) => {
  if (!orderList) return objList;
  if (Array.isArray(orderList) && orderList.length) {
    const order = orderList.reduce((accumulator, id, index) => ({ ...accumulator, [id]: index }), {});
    return objList
      .filter((el) => orderList.includes(el.id))
      .sort((a, b) => {
        return order[a.id] - order[b.id];
      });
  } else if (Array.isArray(orderList)) {
    return [];
  }
};

export const verifyURLAvailability = (url) => {
  return new Promise((resolve, reject) => {
    const request = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    let nbRetry = 0;

    const loop = () => {
      setTimeout(() => {
        request.open('GET', url, false);
        request.send(); // there will be a 'pause' here until the response to come.
        nbRetry += 1;
        if (nbRetry < 5 && request.status === 404) {
          loop();
        } else if (nbRetry >= 5) {
          reject('nbRetry reached');
        } else if (request.status !== 404) {
          resolve('good');
        }
      }, 5000);
    };
    loop();
  });
};

export const validEmailRegex = /^[a-zA-Z0-9._%+-]{1,50}(?:[.+_-][a-zA-Z0-9._%+-]{1,50}){0,50}@([a-zA-Z0-9.-]{1,50}\.[a-zA-Z]{2,})$/i;


export const addDisplayTranslation = (element, lngId) => {
  const translations = element.translations || {};
  const displayTranslation = translations[lngId] || Object.values(translations)[0] || {};
  return {
    ...element,
    displayTranslation,
  };
};

export const isNullOrWhitespace = (input) => {
  if (typeof input === 'undefined' || input == null) return true;
  return `${input}`.replace(/\s/g, '').length < 1;
};

export const shortidGenerate = () => {
  const hexstring = CryptoJS.enc.Hex.stringify(CryptoJS.lib.WordArray.random(16));
  return hexstring.substring(0, 8);
};
